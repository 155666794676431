<script>
  export default {
    name: 'PaPaClassSchedule',
    data() {
      return {
        url: ''
      }
    },


    created() {
      this.url=process.env.VUE_APP_VUE3
    },
  }
</script>

<template>
  <div class="classSchedule">
    <micro-app name="class-schedule" :url="url+'pre_class_schedule'" default-page="/classSchedule" keep-router-state keep-alive iframe ></micro-app>
  </div>
</template>

<style scoped lang="less">
  .classSchedule {
    position: fixed;
    top: 0;
    left: 0;
    min-width: 100vw;
    min-height: 100vh;
    z-index: 999999;
    background-color: #fff;
  }
</style>
